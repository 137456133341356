import './Kontakt.css'
import { useTranslation } from 'react-i18next';


export default function Kontakt() {
    const { t } = useTranslation();

    return (
        <>
            <div className='flex-container-kontakt'>
                <h1 className='heading-h1'>   {t('kontakt.contact')}</h1>

                <p className='impressum-text'>
                    {t('kontakt.wirfreuenuns')}
                    <br />        <br />

                    {t('kontakt.geschäftsführer')}: Santo Sapia
                    <br />        <br />

                    Ellwanger Str.109
                    <br />        <br />



                    73441 Bopfingen
                    <br />        <br />


                    Tel: 07362 / 95 68 966
                    <br />

                    Fax: 07362 / 95 68 967
                    <br />        <br />


                    info@world-system.de
                    <br />        <br />
                </p>
                <a href="mailto: info@world-system.de" type="button" className="btn btn-outline-info mail-container">
                    <span>
                    {t('kontakt.interesse')}
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-mailbox mailbox-mrgn" viewBox="0 0 16 16">
                        <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                        <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                    </svg>

                </a>


            </div>
        </>
    )
}