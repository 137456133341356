import './App.css';
import Navbar from '../Navbar/Navbar';
import Home from '../pages/Home/Home';
import Messebau from '../pages/Messebau/Messebau';
import Kontakt from '../pages/Kontakt/Kontakt';
import Karriere from '../pages/Karriere/Karriere';
import Unternehmen from '../pages/Unternehmen/Unternehmen';
import Footer from '../Footer/Footer';
import Datenschutz from '../pages/Datenschutz/Datenschutz';
import Impressum from '../pages/Impressum/Impressum';

function App() {
  let currentPage;

  switch (window.location.pathname) {
    case '/':
      currentPage = <Home />;
      break;
    case '/messebau':
      currentPage = <Messebau />;
      break;
    case '/kontakt':
      currentPage = <Kontakt />;
      break;
    case '/karriere':
      currentPage = <Karriere />;

      break;
    case '/unternehmen':
      currentPage = <Unternehmen />;
      break;
    case '/datenschutz':
      currentPage = <Datenschutz />;
      break;
    case '/impressum':
      currentPage = <Impressum />;

      break;
  }
  return (
    <>
      <Navbar />
      {currentPage}
      <Footer />
    </> 
  );
}

export default App;
