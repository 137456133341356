import './Messebau.css'
import designimg from '../../bilder/design/messebau-design.jpg';
import { useTranslation } from 'react-i18next';
import imgone from '../../bilder/referenzen/edit-1.jpg';
import imgtwo from '../../bilder/referenzen/edit-2.jpg';
import imgthree from '../../bilder/referenzen/edit-3.jpg';
import imgfour from '../../bilder/referenzen/edit-4.jpg';
import imgfive from '../../bilder/referenzen/edit-5.jpg';
import imgsix from '../../bilder/referenzen/edit-7.jpg';
import imgseven from '../../bilder/referenzen/edit-8.jpg';
import imgeight from '../../bilder/referenzen/edit-9.jpg';
import imgnine from '../../bilder/referenzen/edit-10.jpg';
import imgten from '../../bilder/referenzen/edit-11.jpg';
import imgeleven from '../../bilder/referenzen/edit-12.jpg';
import imgtwelve from '../../bilder/referenzen/edit-13.jpg';
import imgthirteen from '../../bilder/referenzen/edit-14.jpg';
import imgfourteen from '../../bilder/referenzen/edit-15.jpg';









export default function Messebau() {
    const { t } = useTranslation();
    return (
        <>
            <div className='messebau-container'>
                <div className='image-container'>
                    <img id="messebau-img" alt="messebau-bild" src={designimg} />
                    <span className='heading'>{t('messebau.messebautitel')}</span>
                </div>
                <p id='unsere-leistungen' className='text-center mrgn-text'>{t('messebau.leistungen')}</p>
                <div className="container text-center">
                    <div className="row">
                        <div className="col offset-lg-3 col-lg-3">
                            <button type="button" className="btn btn-primary messe-kategorie blue">{t('messebau.messebau')}</button>
                        </div>
                        <div className="col col-lg-3">
                            <button type="button" className="btn btn-warning messe-kategorie orange">{t('messebau.eventmontage')}</button>
                        </div>
                    </div>
                </div>
                <h2 id='referenzen-title'>{t('messebau.referenzen')}</h2>
                <div id="carouselExampleInterval" className="carousel slide carousel-fade center-self" data-bs-ride="carousel" data-bs-interval="4000">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={imgsix} className="d-block w-100" alt="carousel" />
                        </div>

                        <div className="carousel-item">
                            <img src={imgeleven} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgtwelve} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgthirteen} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgfourteen} className="d-block w-100" alt="carousel" />
                        </div>

                        <div className="carousel-item">
                            <img src={imgtwo} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgthree} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgfour} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgnine} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgfive} className="d-block w-100" alt="carousel" />
                        </div>

                        <div className="carousel-item">
                            <img src={imgone} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgseven} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgeight} className="d-block w-100" alt="carousel" />
                        </div>
                        <div className="carousel-item">
                            <img src={imgten} className="d-block w-100" alt="carousel" />
                        </div>

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                        </svg>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    )
}