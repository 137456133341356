import './Unternehmen.css'
import bernd from '../../bilder/personen/bernd.png';
import helmut from '../../bilder/personen/helmut.png';
import lukasz from '../../bilder/personen/lukasz.png';
import lukaszzwei from '../../bilder/personen/lukasztransparent.png';
import maciej from '../../bilder/personen/Maciej.png';
import santo from '../../bilder/personen/santo.jpg';
import pinio from '../../bilder/personen/pinio.png';
import dennis from '../../bilder/personen/dennis.png';
import mike from '../../bilder/personen/MikeSa.png';
import stanislaw from '../../bilder/personen/Stanislaw.png';
import tomasz from '../../bilder/personen/Tomasz.png';
import erik from '../../bilder/personen/erik.png';
import uwe from '../../bilder/personen/uwe.png';
import mariusz from '../../bilder/personen/mariusz.png';
import krzysztof from '../../bilder/personen/Krzysztof.png';
import mariuszzwei from '../../bilder/personen/mariusz2.png';
import melanie from '../../bilder/personen/melanie.png';











import designimg from '../../bilder/design/bild-ws.png';
import { useTranslation } from 'react-i18next';



export default function Unternehmen() {
    const { t } = useTranslation();


    return (
        <>
            <div className='unternehmen-container'>
                <div className='image-container'>
                    <img id="messebau-img" alt="messebau-bild" src={designimg} />
                    <span className='heading'>{t('company.unternehmen')}</span>
                </div>
                <p className='align-text heading-two'>{t('company.quote')}</p>
                <p className='description-text'>

                    {t('company.text1')}<br />
                    {t('company.text2')}<br /><br />
                    {t('company.text3')}<br /><br />
                    {t('company.text4')}<br /><br />
                </p>
                <h2>{t('company.unserteam')}</h2>

                {/* Geschäftsführer */}
                <div className="container text-center">
                    <p className='heading-images'>{t('company.geschäftsführer')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={santo} />
                            <div>
                                <p className='align-text'>Santo</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                        </div>
                        <div className="col-lg-3">
                        </div>
                    </div>
                </div>
                {/* 2 col
                <div className="container text-center">
                    <p className='heading-images'>Projektleiter</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={bernd} />
                            <div>
                                <p className='align-text'>Bernd</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={helmut} />
                            <div>
                                <p className='align-text'>Helmut</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                        </div>
                    </div>
                </div> */}

                {/* Vorarbeiter */}
                <div className="container text-center">
                    <p className='heading-images'>{t('company.projektleiter')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={bernd} />
                            <div>
                                <p className='align-text'>Bernd</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={helmut} />
                            <div>
                                <p className='align-text'>Helmut</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={pinio} />
                            <div>
                                <p className='align-text'>Pinio</p>
                                <p className='pinio-text-small'>{t('company.geschäftsführerpolen')}</p>
                            </div>
                        </div>
                    </div>

                </div>



                {/* Vorarbeiter */}
                <div className="container text-center">
                    <p className='heading-images'>{t('company.vorarbeiter')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={lukasz} />
                            <div>
                                <p className='align-text'>Lukasz</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={maciej} />
                            <div>
                                <p className='align-text'>Maciej</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={lukaszzwei} />
                            <div>
                                <p className='align-text'>Lukasz</p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Projektassistent */}
                <div className="container text-center">
                    <p className='heading-images'>{t('company.projektassistent')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={dennis} />
                            <div>
                                <p className='align-text'>Dennis</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={melanie} />
                            <div>
                                <p className='align-text'>Melanie</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={mike} />
                            <div>
                                <p className='align-text'>Mike</p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Montageleiter */}
                <div className="container text-center">
                    <p className='heading-images'>{t('company.montageleiter')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={stanislaw} />
                            <div>
                                <p className='align-text'>Stanislaw</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={tomasz} />
                            <div>
                                <p className='align-text'>Tomasz</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={krzysztof} />
                            <div>
                                <p className='align-text'>Krzysztof</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container text-center">
                    <p className='heading-images'>{t('company.logistiker')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={uwe} />
                            <div>
                                <p className='align-text'>Uwe</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={mariusz} />
                            <div>
                                <p className='align-text'>Mariusz</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                        </div>
                    </div>
                </div>

                {/* Elektriker */}
                <div className="container text-center">
                    <p className='heading-images'>{t('company.elektriker')}</p>
                    <hr></hr>
                    <div className="row justify-content-md-center mrgn-top-five">
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={erik} />
                            <div>
                                <p className='align-text'>Erik</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <img className='img-person' alt="person" src={mariuszzwei} />
                            <div>
                                <p className='align-text'>Mariusz</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}