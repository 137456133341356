import './Footer.css'
import companyLogo from '../../src/bilder/svg/instagram.svg';
import { useTranslation, Trans } from 'react-i18next';

const lngs = {
    en: { nativeName: 'En' },
    de: { nativeName: 'De' }
};



export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="footer-container bg-dark">
                <footer>
                    <div className="inner-footer">

                        <div className='lng-switch'>
                            {Object.keys(lngs).map((lng) => (
                                <button type="button" className="btn btn-secondary mrgn-lft" key={lng} style={{
                                    textShadow: i18n.resolvedLanguage === lng ? '0px 0px 10px aliceblue' : 'none',
                                    fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', backgroundColor: i18n.resolvedLanguage === lng ? '#8b7e65' : 'unset'
                                }} onClick={() => i18n.changeLanguage(lng)}>
                                    {lngs[lng].nativeName}
                                </button >
                            ))}
                        </div>
                        <a className='text-glow' href='/impressum'>{t('footer.impressum')}</a>
                        <a className='text-glow' href='/datenschutz'>{t('footer.datenschutz')}</a>
                        <div className='flex-icons'>
                            <a href="tel:073629568966" type="button" className="btn btn-outline-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
                                </svg>
                            </a>
                        </div>

                    </div>
                </footer>
            </div>
        </>
    )
}