import React from "react";
import companyLogo from '../../src/bilder/gif-ws.gif';
import './Navbar.css'
import { useTranslation } from 'react-i18next';



export default function Navbar() {
    const { t } = useTranslation();

    function resetLocation() {
        window.location.pathname = "";
    }

    function changeBgMenu(){
        document.getElementById("navbarNav").style.backgroundColor = "#2e2e2e";
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light" id="nav-element">
                <img alt="logo" className="cursor-pointer logo-margin" src={companyLogo} onClick={resetLocation} width="80px" />
                <a className="navbar-brand cursor-pointer logo-margin logo-style" onClick={resetLocation}>World System GmbH ®</a>
                <button className="navbar-toggler hamburger-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                    aria-expanded="false" aria-label="Toggle navigation" onClick={changeBgMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav routing-elements">
                        <li className="nav-item active">
                            <a className={window.location.pathname === "/" ? "nav-link active-tab cursor-pointer" : "nav-link cursor-pointer"} onClick={resetLocation}>{t('nav.home')}</a>
                        </li>
                        <li className="nav-item">
                            <a id="unternehmen" className={window.location.pathname === "/unternehmen" ? "nav-link active-tab" : "nav-link"} href="/unternehmen">{t('nav.unternehmen')}</a>
                        </li>
                        <li className="nav-item">
                            <a id="messebau" className={window.location.pathname === "/messebau" ? "nav-link active-tab" : "nav-link"} href="/messebau">{t('nav.messebau')}</a>
                        </li>
                        <li className="nav-item">
                            <a id="kontakt" className={window.location.pathname === "/kontakt" ? "nav-link active-tab" : "nav-link"} href="/kontakt" >{t('nav.kontakt')}</a>
                        </li>
                        <li className="nav-item">
                            <a id="karriere" className={window.location.pathname === "/karriere" ? "nav-link active-tab" : "nav-link"} href="/karriere">{t('nav.karriere')}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>

    )
}