import homebackground from '../../bilder/gallerie/homebackground.jpg';
import './Home.css'
import { useTranslation } from 'react-i18next';



export default function Home() {
    const { t } = useTranslation();
    return (
        <>
            <div className='background'>
            <div className='home-container'>
                <img src={homebackground} className='bg-img'></img>
            </div>
            </div>
            
        </>
    )
}