import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    home:{
                        greetmsg: {
                            p1: 'Welcome',
                            p2: 'to your partner for assembly'
                        },  
                        carousel: {
                            description1: 'Welcome',
                            description2: 'to your partner for assembly',
                            description3: 'World System®'
                        }
                    },
                    nav:{
                        home: "Home",
                        unternehmen: 'Company',
                        messebau: 'Stand Construction',
                        kontakt: 'Contact',
                        karriere: 'Career'
                    },
                    footer:{
                        datenschutz: 'Privacy',
                        impressum: 'Imprint'
                    },
                    company:{
                        unternehmen:'Company',
                        unserteam: 'Our team',
                        quote: '"Quality is our Scaling."',
                        text1: 'As an innovative company, World System GmbH. was founded in 2004 by Santo Sapia.',
                        text2: 'The reliability, quality and passion allowed both the customer base and the number of employees to grow quickly.',
                        text3: 'With a network of dedicated employees, competent partners, high-tech equipment and concentrated know-how, World System has become one of the assembly experts for medium-sized companies and automotive groups.',
                        text4: 'As a competent assembly team, we are happy to turn your plans into reality for you!',
                        geschäftsführer: 'Managing Director',
                        projektleiter: 'Project Manager',
                        geschäftsführerpolen: 'Managing Director World System Poland',
                        vorarbeiter: 'Foreman',
                        projektassistent: 'Project Assistant',
                        montageleiter: 'Assembly Manager',
                        logistiker: 'Logistician',
                        elektriker: 'Electrician'
                    },
                    messebau: {
                        messebautitel: 'Stand Construction',
                        leistungen: 'Our Services as Assembly Team',
                        messebau: 'Stand Construction',
                        objekteinrichtung: 'Object Furnishing',
                        ladenbau: 'Shop Fitting',
                        eventmontage: 'Event',
                        referenzen: 'References'
                    },
                    kontakt:{
                        contact: 'Contact',
                        wirfreuenuns: 'We are looking forward to see you!',
                        geschäftsführer: 'Managing Director',
                        interesse: 'Interessted?',
                    },
                    karriere:{
                        karriere: 'Carreer',
                        text1: 'We are looking for ambitious employees as well as subcontractors',
                        text2: 'in the following activities:',
                        elektriker: 'Electrician',
                        schreiner: 'Carpenter',
                        montageleiter: 'Assembly Manager',
                        monteure: 'Fitters/tradesmen of all kinds',
                        logistiker: 'Logistician',
                        bewerben: 'Apply now by E-Mail'
                    }
                  
                }
            },
            de: {
              translation: {
                home:{
                    greetmsg: {
                        p1: 'Willkommen',
                        p2: 'Ihr Partner als Montageteam'
                    },
                    carousel: {
                        description1: 'Herzlich Willkommen',
                        description2: 'bei Ihrem Partner als Montageteam',
                        description3: 'World System®'
                    }
                },
                nav:{
                    home: 'Startseite',
                    unternehmen: 'Unternehmen',
                    messebau: 'Messebau',
                    kontakt: 'Kontakt',
                    karriere: 'Karriere'
                },
                footer:{
                    datenschutz: 'Datenschutz',
                    impressum: 'Impressum'
                },
                company:{
                    unternehmen:'Unternehmen',
                    unserteam: 'Unser Team',
                    quote: '"Qualität ist unser Maßstab."',
                    text1: 'Als innovatives Unternehmen wurde die World System GmbH. im Jahr 2004 von Santo Sapia gegründet.',
                    text2: 'Durch die Zuverlässigkeit, Qualität und Leidenschaft konnte sowohl der Kundenstamm und die Mitarbeiteranzahl schnell wachsen.',
                    text3: 'Mit einem Netzwerk aus engagierten Mitarbeitern, kompetenten Partnern, einer hochtechnisierter Ausstattung sowie geballtem Know How zählt World System inzwischen zu den Montage-Experten bei mittelständischen Unternehmen & Automobilkonzernen.',
                    text4: 'Als kompetentes Montageteam freuen wir uns Ihre Pläne für Sie in die Realität umzusetzen!',
                    geschäftsführer: 'Geschäftsführer',
                    projektleiter: 'Projektleiter',
                    geschäftsführerpolen: 'Geschäftsführer World System Polen',
                    vorarbeiter: 'Vorarbeiter',
                    projektassistent: 'Projektassistent',
                    montageleiter: 'Montageleiter',
                    logistiker: 'Logistiker',
                    elektriker: 'Elektriker'


                },  
                messebau: {
                    messebautitel: 'Messebau',
                    leistungen: 'Unsere Leistungen als Montageteam',
                    messebau: 'Messebau',
                    objekteinrichtung: 'Objekteinrichtung',
                    ladenbau: 'Ladenbau',
                    eventmontage: 'Event',
                    referenzen: 'Referenzen'
                },
                kontakt:{
                    contact: 'Kontakt',
                    wirfreuenuns: 'Wir freuen uns auf Sie!',
                    geschäftsführer: 'Geschäftsführer',
                    interesse: 'Interesse?',
                },
                karriere:{
                    karriere: 'Karriere',
                    text1: 'Wir suchen ambitionierte Mitarbeiter sowie Subunternehmer',
                    text2: 'in folgenden Tätigkeiten:',
                    elektriker: 'Elektriker',
                    schreiner: 'Schreiner',
                    montageleiter: 'Montageleiter',
                    monteure: 'Monteure/Handwerker aller Art',
                    logistiker: 'Logistiker',
                    bewerben: 'Bewerben Sie sich jetzt per E-Mail'
                }
               
              }
            }
        }
    });

export default i18n;